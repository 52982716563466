<template>
  <div class="login_container">
    <IndexInner v-if="defaultPage"></IndexInner>
    <IndexInnerBv v-if="is8V"></IndexInnerBv>
    <!-- <IndexInner v-if="is8V && darkMode == 1"></IndexInner> -->
  </div>
</template>
<script>
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
import { translateTitle } from "@/assets/js/i18n";
import { mapActions, mapGetters } from "vuex";
import IndexInner from "@/pages/login/index-inner.vue";
import IndexInnerBv from "@/pages/login/index-inner-bv.vue";
export default {
  mixins: [],

  data() {
    return {
      is8V: false,
      defaultPage: false,
    };
  },
  components: { IndexInner, IndexInnerBv },
  computed: {
    // ...mapGetters({
    //   darkMode: "user/darkMode"
    // })
  },
  created() {
    // this.defaultPage = true;
    if (VUE_UNION_STATION == "8V") {
      this.is8V = true;
    } else {
      this.defaultPage = true;
    }
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss"></style>
